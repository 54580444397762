import React, { useState, useRef } from "react"
import Slider from "react-slick"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Health = ({ data }) => {
  const slider = useRef(null)

  var HealthSlick = {
    // dots: true,
    infinite: true,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    // appendDots: dots => <><div className="col p-0 health-slider-ad"><div className="health-slider-dots"><ul clasName="slick-dots">{dots}</ul></div></div></>,
    // appendArrows: ('.health-slider-nav'),
    // appendDots: ('.health-slider-dots'),
    // prevArrow: "<button className='slick-prev'><img src='./assets/images/arrow-l.svg' alt='#' /></button>",
    // nextArrow: "<button className='slick-next'><img src='./assets/images/arrow-r.svg' alt='#' /></button>",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const {
    healthSliderHeadline,
    healthSlider,
  } = data

  const [idx, setIdx] = useState(0)
  return (
    <>
      <section className="health-slider-sec">
        <div className="container">
          <div className="row">
            <div className="col">
              {renderRichText(healthSliderHeadline)}
            </div>

            <div className="col health-slider-col">
              <div className="row m-0 health-slider-content">
                {healthSlider.map((item, i) => (
                  <div
                    className={`col p-0 health-slider${i + 1}-content ${
                      idx === i && "active"
                    }`}
                    id={`health-slider${i + 1}-content`}
                  >
                    <h3>{item.title}</h3>
                    <p>{item.content.content}</p>
                    <a href={item.linkUrl}>
                      {item.linkLabel}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.243"
                        height="13.501"
                        viewBox="0 0 20.243 13.501"
                      >
                        <path
                          id="Icon_ionic-ios-arrow-round-forward"
                          data-name="Icon ionic-ios-arrow-round-forward"
                          d="M20.784,11.51a.919.919,0,0,0-.007,1.294l4.275,4.282H8.782a.914.914,0,0,0,0,1.828H25.045L20.77,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.784,11.51Z"
                          transform="translate(-7.875 -11.252)"
                          fill="#ff0245"
                        />
                      </svg>
                    </a>
                    <p>
                      <span>
                        {i + 1} of {healthSlider.length}
                      </span>
                    </p>
                  </div>
                ))}
              </div>
              <div className="row m-0 health-slider-row health-slider-row1">
                <div className="col p-0 health-slider">
                  <Slider {...HealthSlick} ref={slider}>
                    {healthSlider.map((item, i) => (
                      <div onClick={() => setIdx(i)}>
                        <img src={item.image.file.url} alt="#" />
                      </div>
                    ))}
                  </Slider>
                  <div className="col p-0 health-slider-ad">
                    <div className="health-slider-dots">
                      <ul clasname="slick-dots">
                        {healthSlider.map((item, i) => (
                          <li
                            className={`${idx === i ? "slick-active" : ""}`}
                            key={item.id}
                            onClick={() => {
                              slider?.current?.slickGoTo(i)
                              setIdx(i)
                            }}
                          >
                            <button>{i + 1}</button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="nextSlickBtn"
            onClick={() => {
              slider?.current?.slickNext()
              healthSlider.length - 1 !== idx ? setIdx(idx + 1) : setIdx(0)
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.243"
              height="10.501"
              viewBox="0 0 20.243 13.501"
            >
              <path
                id="Icon_ionic-ios-arrow-round-forward"
                data-name="Icon ionic-ios-arrow-round-forward"
                d="M20.784,11.51a.919.919,0,0,0-.007,1.294l4.275,4.282H8.782a.914.914,0,0,0,0,1.828H25.045L20.77,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.784,11.51Z"
                transform="translate(-7.875 -11.252)"
                fill="#151515"
              />
            </svg>
          </button>
          <button
            className="prevSlickBtn"
            onClick={() => {
              slider?.current?.slickPrev()
              idx === 0 ? setIdx(healthSlider.length - 1) : setIdx(idx - 1)
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.243"
              height="10.501"
              viewBox="0 0 20.243 13.501"
            >
              <path
                id="Icon_ionic-ios-arrow-round-forward"
                data-name="Icon ionic-ios-arrow-round-forward"
                d="M20.784,11.51a.919.919,0,0,0-.007,1.294l4.275,4.282H8.782a.914.914,0,0,0,0,1.828H25.045L20.77,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.784,11.51Z"
                transform="translate(-7.875 -11.252)"
                fill="#151515"
              />
            </svg>
          </button>
        </div>
        </section>
      </>
  )
}

export default Health
