import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulAboutPage {
        heroImage1 {
          file {
            url
          }
        }
        heroImage2 {
          file {
            url
          }
        }
        heroTitle
        heroContent {
          heroContent
        }
      }
    }
  `)
  const { heroImage1, heroImage2, heroTitle, heroContent } =
    data.contentfulAboutPage
  return (
    <section className="hero-sec about-hero-sec">
      <div className="container">
        <div className="row">
          <div className="col col-md-6 hero-sec-image">
            <div className="hero-sec-img1">
              <img src={heroImage1.file.url} alt="#" />
            </div>
            <div className="hero-sec-img2">
              <img src={heroImage2.file.url} alt="#" />
            </div>
          </div>
          <div className="col col-md-6 hero-sec-content d-flex align-items-center">
            <div>
              <h1>{heroTitle}</h1>
              <p>{heroContent.heroContent}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
