import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Mission = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulAboutPage {
        missionHeading
        missionSubheading
        missionImage {
          file {
            url
          }
        }
        missionTitle
        missionContent {
          missionContent
        }
      }
    }
  `)
  const {
    missionHeading,
    missionSubheading,
    missionImage,
    missionTitle,
    missionContent,
  } = data.contentfulAboutPage
  return (
    <section className="mission-sec">
      <div className="container">
        <div className="row">
          <div className="col">
            <h6>{missionHeading}</h6>
            <h1>{missionSubheading}</h1>
          </div>
          <div className="col mission-sec-content">
            <img src={missionImage.file.url} alt="#" />
            <div className="mission-sec-box">
              <h3>{missionTitle}</h3>
              <p>{missionContent.missionContent}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Mission
