import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Values = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulAboutPage {
        valuesTitle
        valuesContent {
          valuesContent
        }
        values {
          id
          image {
            file {
              url
            }
          }
          title
          content {
            content
          }
        }
      }
    }
  `)
  const { valuesTitle, valuesContent, values } = data.contentfulAboutPage
  return (
    <section className="value-sec" id="value-sec">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>{valuesTitle}</h2>
            <p>{valuesContent.valuesContent}</p>
          </div>
          {values.map(value => (
            <div className="col col-md-4 value-sec-inner" key={value.id}>
              <div>
                <img src={value.image.file.url} alt="#" />
              </div>
              <h6>{value.title}</h6>
              <p>{value.content.content}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Values
